<template>
    <div>
        <b-row>
            <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('faculty_code')">
                                    {{ formData.faculty_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('department_code')">
                                    {{ formData.department_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_code')">
                                    {{ formData.code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_name')">
                                    {{ getLocaleText(formData, 'name') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_level')">
                                    {{ getLocaleText(formData, 'level_text') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_language')">
                                    {{ formData.language }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_duration')">
                                    {{ formData.duration }}
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="mt-2">
                <b-card>
                    <b-row>
                        <b-col md="12">
                            <b-table
                                :empty-filtered-text="$t('no_result')"
                                :empty-text="$t('no_result')"
                                bordered
                                striped
                                responsive
                                :items="tableData"
                                :fields="tableFields"
                                show-empty
                                class="mb-5 mt-2 table-dropdown no-scrollbar border rounded">
                                <template v-for="item in tableFields" v-slot:[`head(${item.key})`]="data">
                                    <span v-if="item.label != ''" class="cursor-pointer" v-b-tooltip.hover :title=item.label>{{ item.key }}</span>
                                </template>
                                <template v-for="(item, index) in tableData" v-slot:[`cell(${index+1})`]="data">
                                    <b-form-checkbox
                                        @change="checking(index+1, data.item[index+1].id)"
                                        v-if="data.item[index+1]"
                                        :id="'checkbox-'+(index+1)+'-'+data.item[index+1].id"
                                        v-model="data.item[index+1].status"
                                        value="checked"
                                        unchecked-value="not_checked" />
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Components

// Services
import TyycBasicFieldOutcomesService from "@/services/TyycBasicFieldOutcomesService"
import ProgramOutcome from "@/services/ProgramOutcome"
import ProgramBasicFieldOutcomesService from "@/services/ProgramBasicFieldOutcomesService"

// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";
import qs from "qs";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    props: {
        program: {
            type: Object,
        },
    },
    data() {
        return {
            formLoading: false,
            formData: {},
            tableData: [],
            tableFields: [
                {
                    key: 'info',
                    label: ''
                }
            ],
            tyycOutcomes: null,
            status: []
        };
    },

    created() {
        this.formData = this.program
        this.getProgramOutcome()
    },

    watch: {
        tableData: {
            handler() {
                this.getData()
            }
        }
    },

    methods: {
        getData(){
            const config = {
                params: {
                    filter: {
                        program_code: this.program.code
                    },
                    sort: 'id',
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            ProgramBasicFieldOutcomesService.getAll(config).then(response => {
                response.data.data.forEach(data =>{
                    let key = null
                    this.tableFields.forEach(obj =>{
                        if(obj.program_outcome_id && obj.program_outcome_id == data.program_outcome_id){
                            key = obj.key
                        }
                    })

                    this.tableData.forEach((item, index1) => {
                        if(item[key] && item[key].id == data.tyyc_basic_field_outcome_id){
                            this.tableData[index1][key].status = 'checked'
                            this.tableData[index1][key].complex_id = data.id
                        }
                    })
                })
            }).catch(e => {
                this.showErrors(e)
            })
        },

        getTyycLevelOutcomes(){
            const config = {
                params: {
                    sort: 'id',
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            TyycBasicFieldOutcomesService.getAll(config).then(response => {
                response.data.data.forEach((itm, index) => {
                    this.tableData.push({
                        info: (index+1).toString()+' - '+this.getLocaleText(itm, 'tyyc_basic_field_type_name')
                    })
                    let objData = {}
                    this.tableFields.forEach((item, i) => {
                        if(i > 0){
                            objData[i] = {
                                id: itm.id,
                                status: 'not_checked'
                            }
                        }
                    })
                    this.tableData.push({
                        info: this.getLocaleText(itm, 'outcome'),
                        ...objData
                    })
                })
            }).catch(e => {
                this.showErrors(e)
            })
        },

        getProgramOutcome(){
            const config = {
                params: {
                    filter: {
                        program_code: this.program.code
                    },
                    sort: 'rank',
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            ProgramOutcome.getAll(config).then(response => {
                response.data.data.forEach((itm, index) => {
                    let kl = index + 1
                    this.tableFields.push({
                        key: kl.toString(),
                        label: this.getLocaleText(itm, 'program_outcome_definition_name'),
                        program_outcome_id: itm.id
                    })
                })
            }).finally(() =>{
                this.getTyycLevelOutcomes()
            }).catch(e => {
                this.showErrors(e)
            })
        },

        checking(key, tyycBasicFieldOutcomeId){
            let formData = {
                program_outcome_id: this.tableFields[key].program_outcome_id,
                tyyc_basic_field_outcome_id: tyycBasicFieldOutcomeId
            }

            this.tableData.forEach((itm, index) => {
                if(itm[key] && itm[key].id == tyycBasicFieldOutcomeId){
                    if(itm[key].status == 'checked'){
                        if(this.checkPermission('programbasicfieldoutcome_store')){
                            ///Store
                            ProgramBasicFieldOutcomesService.store(formData).then(response => {
                                this.tableData[index][key].complex_id = response.data.data.id
                                this.$toast.success(this.$t('api.'+response.data.message))
                            }).catch(e => {
                                this.showErrors(e)
                            })
                        } else {
                            this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                        }
                    } else {
                        if(this.checkPermission('programbasicfieldoutcome_delete')){
                            /// Delete
                            ProgramBasicFieldOutcomesService.del(this.tableData[index][key].complex_id).then(response => {
                                this.$toast.success(this.$t('api.'+response.data.message))
                                delete this.tableData[index][key].complex_id
                            }).catch(e => {
                                this.showErrors(e)
                            })
                        } else {
                            this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                        }
                    }
                }
            })
        }

    },
}
</script>
<style scoped>
    .cursor-pointer {
        cursor: pointer;
    }
</style>
