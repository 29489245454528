import Api from '@/services/Index';

const get = async (programCode) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/ects/program-courses/'+programCode)
}

const update = async (courseId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/ects/update-course-coordinator/'+courseId, formData)
}

export default {
    get,
    update,
}
