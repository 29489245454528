<template>
    <div>
        <b-row>
            <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('faculty_code')">
                                    {{ formData.faculty_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('department_code')">
                                    {{ formData.department_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_code')">
                                    {{ formData.code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_name')">
                                    {{ getLocaleText(formData, 'name') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_level')">
                                    {{ getLocaleText(formData, 'level_text') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_language')">
                                    {{ formData.language }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_duration')">
                                    {{ formData.duration }}
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
        </b-row>
        <b-row v-if="isNew">
            <b-col md="12" class="mt-2">
                <b-card :header="courseName.toUpper()" header-tag="header">
                    <ValidationObserver ref="formValidate">
                        <b-row>
                            <b-col sm="12" md="6">
                                <ValidationProvider name="name_tr" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('course_name')+' (TR)'">
                                        <b-form-input type="text" v-model="newFormData.name_tr" />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="6">
                                <ValidationProvider name="name_en" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('course_name')+' (EN)'">
                                        <b-form-input type="text" v-model="newFormData.name_en" />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="6">
                                <ValidationProvider name="language" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('course_language')">
                                        <b-form-input type="text" v-model="newFormData.language" />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="6">
                                <ValidationProvider name="coordinator_id" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('coordinator')">
                                        <staff-auto-complete
                                            :value-object="valueObject"
                                            v-model="newFormData.coordinator_id"
                                            :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col sm="12" md="12" class="d-flex justify-content-center mt-2">
                                <b-button variant="primary" @click="save" :disabled="formLoading">{{ $t("save") }}</b-button>
                                <b-button variant="outline-secondary" @click="isNew = false" class="ml-2">{{ $t("cancel") }}</b-button>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="mt-2">
                <b-card>
                    <b-row>
                        <b-col md="12">
                            <b-table
                                :empty-filtered-text="$t('no_result')"
                                :empty-text="$t('no_result')"
                                bordered
                                striped
                                responsive
                                :items="tableData"
                                :fields="tableFields"
                                show-empty
                                class="mb-5 mt-2 table-dropdown no-scrollbar border rounded">
                                <template #cell(show_in_diploma_supplement)="row">
                                    {{ row.item.show_in_diploma_supplement == true ? $t('yes') : $t('no') }}
                                </template>
                                <template #cell(buttons)="row">
                                    <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                                        <template #button-content>
                                            <i class="ri-more-fill"></i>
                                        </template>
                                        <div id="dropdownListHead">
                                            <b-dropdown-item @click="openUpdateBox(row.item)">
                                                <i class="ri-edit-box-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('edit')}}
                                            </b-dropdown-item>
                                        </div>
                                    </b-dropdown>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Components
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete"

// Services
import EctsProgramService from "@/services/EctsProgramService"


// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        StaffAutoComplete
    },
    props: {
        program: {
            type: Object,
        },
    },
    data() {
        return {
            formLoading: false,
            formData: {},
            tableData: [],
            tableFields: [
                {
                    key: 'buttons',
                    label: ''
                },
                {
                    key: 'code',
                    label: this.$t('course_code')
                },
                {
                    key: 'name',
                    label: this.$t('course_name')
                },
                {
                    key: 'name_tr',
                    label: 'TR'
                },
                {
                    key: 'name_en',
                    label: 'EN'
                },
                {
                    key: 'language',
                    label: this.$t('course_language')
                },
                {
                    key: 'coordinator_name',
                    label: this.$t('coordinator')
                }
            ],
            isNew: false,
            newFormData: {},
            isDefinition: true,
            definitionForm: {},
            courseName: null,
            valueObject: {}
        };
    },
    created() {
        this.formData = this.program
        this.getData()
    },
    methods: {
        getData(){
            EctsProgramService.get(this.program.code).then(response => {
                this.tableData = response.data.data
            }).catch(e => {
                this.showErrors(e)
            })
        },

        newOutcome() {
            this.newFormData = {}
            return this.isNew = !this.isNew
        },

        async save(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.formLoading = true
                EctsProgramService.update(this.newFormData.id, this.newFormData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.formLoading = false
                    this.getData()
                    this.isNew = false
                    this.newFormData = {}
                }).catch(e => {
                    this.showErrors(e, this.$refs.formValidate);
                    this.formLoading = false
                })
            }
        },

        openUpdateBox(data){
            this.isNew = true
            this.courseName = data.code+' - '+data.name
            this.newFormData = data
            this.valueObject = {
                value: data.coordinator_id,
                text: data.coordinator_name
            }
        },

    },
}
</script>
